import React from "react";
import { BsTwitter, BsInstagram, BsGithub } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://twitter.com/Coding_way"
          target="_blank"
          rel="noreferrer"
        >
          <BsTwitter />
        </a>
      </div>

      <div>
        <a
          href="https://www.instagram.com/coding_way/"
          target="_blank"
          rel="noreferrer"
        >
          <BsInstagram />
        </a>
      </div>

      <div>
        <a href="https://github.com/kingola1" target="_blank" rel="noreferrer">
          <BsGithub />
        </a>
      </div>

      <div>
        <a
          href="https://www.facebook.com/Coding_Way-115958397349966"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
